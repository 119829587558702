import { DateTime } from 'luxon'
import { campaigntrack, realhub } from 'assets/icons'
import config from 'config'

export function encodeParams(options = {}) {
  const esc = encodeURIComponent
  const keys = Object.keys(options)
  const query = []

  keys.map((key) => {
    const value = options[key]

    if (value && Array.isArray(value)) {
      const arrayKey = `${esc(key)}[]`

      value.forEach((valueItem) => {
        query.push(`${arrayKey}=${esc(valueItem)}`)
      })
    } else if (value) {
      const arrayKey = `${esc(key)}`

      query.push(`${arrayKey}=${esc(value)}`)
    } else {
      return null
    }
  })

  return query.join('&')
}

export function previewOrientation(height, width) {
  let orientation = 'portrait'

  if (width >= height) {
    orientation = 'landscape'
  }

  return orientation
}

export function previewScale(orientation, holderSize, width, height) {
  let scale = 1

  if (orientation === 'landscape') {
    scale = (holderSize.width - holderSize.padding * 2) / width
  } else {
    scale = (holderSize.height - holderSize.padding * 2) / height
  }

  return scale
}

export function previewMargins(orientation, holderSize, imageSize) {
  const margins = {
    top: orientation === 'landscape' ? (holderSize.height - imageSize.height) / 2 : holderSize.padding,
    left: orientation === 'portrait' ? (holderSize.width - imageSize.width) / 2 : holderSize.padding,
  }

  return margins
}

export function objectsToArray(objects) {
  let array = []

  if (objects) {
    array = Object.keys(objects).map((key) => objects[key])
  }

  return array
}

export function sortArrayBy(array, direction = 'asc', sortKey = 'title') {
  const isStringSortKey = typeof sortKey === 'string'
  const getSortColumn = (item) => (isStringSortKey ? item[sortKey] : sortKey(item))

  const sorted = array.sort((a, b) => {
    const compareA = getSortColumn(a)?.toUpperCase()
    const compareB = getSortColumn(b)?.toUpperCase()

    if (compareA > compareB) return direction === 'asc' ? 1 : -1
    if (compareA < compareB) return direction === 'asc' ? -1 : 1

    return 0
  })

  return sorted
}

export function sortEntitiesBy(entities, direction, sortKey) {
  const array = Object.values(entities)
  return sortArrayBy(array, direction, sortKey)
}

export function getCookies() {
  const cookies = {}

  const parts = document.cookie.split('; ')
  parts.forEach((part) => {
    const [name, value] = part.split('=')
    cookies[name] = decodeURIComponent(value)
  })

  return cookies
}

export function convertBytesTo(fileSize, outputFormat = 'MB') {
  if (outputFormat === 'MB') {
    const size = fileSize / 1024 ** 2

    return `${Math.round(size * 100) / 100} ${outputFormat}`
  }

  return null
}

export function covertNumberToTwoDigit(value) {
  const valueLength = value.toString().length

  if (valueLength === 0) return '00'

  return valueLength === 1 ? `0${value}` : value.toString()
}

export function stringToKey(value) {
  if (value) {
    return value.toLowerCase().replace(/\s/g, '_')
  }

  return ''
}

export function digObject(target, keyString, defaultValue) {
  const keys = keyString.split('.')

  const value = keys.reduce(
    (acc, key) => {
      const accValue = typeof acc === 'undefined' || acc === null ? acc : acc[key]
      return accValue
    },
    { ...target }
  )

  if (value === null || value === undefined) return defaultValue

  return value
}

export function deepSetObject(source, keyString, value) {
  const updated = source ? JSON.parse(JSON.stringify(source)) : {}

  const keys = keyString.split('.')
  const lastKey = keys.pop()

  const lastObject = keys.reduce((acc, key) => {
    acc[key] = acc[key] || {}
    return acc[key]
  }, updated)

  lastObject[lastKey] = value

  return updated
}

function findArrayIndex(array, value, options = {}) {
  const { deepCompare, useObjectKey } = options

  // Replace items with the same key
  if (useObjectKey) {
    return array.findIndex((item) => item[useObjectKey] === value[useObjectKey])
  }

  // Deep Compare - compare objects by stringifying them
  if (deepCompare) {
    return array.findIndex((item) => JSON.stringify(item) === JSON.stringify(value))
  }

  // Simple Array
  return array.findIndex((item) => item === value)
}

export function toggleArray(array, value, options = {}) {
  const { useObjectKey } = options

  const updatedArray = array ? JSON.parse(JSON.stringify([...array])) : []

  const index = findArrayIndex(updatedArray, value, options)

  if (index !== -1) {
    // useObjectKey replaces the original object if match found
    if (useObjectKey) {
      updatedArray.splice(index, 1, value)
      return updatedArray
    }

    updatedArray.splice(index, 1)
  }

  if (index === -1) {
    updatedArray.push(value)
  }

  return updatedArray
}

export function groupBy(array, groupKey, customItem) {
  const isStringGroupKey = typeof groupKey === 'string'
  const grouped = []

  array.forEach((item) => {
    const key = isStringGroupKey ? item[groupKey] : groupKey(item)
    const collection = grouped.find((i) => i.groupKey === key)
    const formattedItem = customItem ? customItem(item) : item

    if (!collection) {
      grouped.push({
        groupKey: key,
        items: [formattedItem],
      })
    } else {
      collection.items.push(formattedItem)
    }
  })

  return grouped
}

export function generateRedirectUrl() {
  if (!window) return null

  const {
    location: { hash, pathname, search },
  } = window

  return encodeURIComponent(pathname + search + hash)
}

export function matchFilterString(string, filterString) {
  if (!string) return false

  if (filterString) {
    return string.toLowerCase().includes(filterString.toLowerCase())
  }
  return true
}

export function matchFilterNumber(number, filterNumber) {
  if (filterNumber) return Number(number) === Number(filterNumber)
  return true
}

export function matchFilterArrayIncludes(array, value) {
  if (!array) return false

  if (value) return array.includes(value)
  return true
}

export function scrollIntoViewWithOffset(element, offset) {
  const top = element.getBoundingClientRect().top + window.pageYOffset + (offset || -85)

  return window.scrollTo({
    behavior: 'smooth',
    top: top,
  })
}

export function trimAndLowerCase(value) {
  const trimString = value.replace(/\s/g, '').toLowerCase()

  return trimString
}

export function formatToDollar(value) {
  const dollars = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
  })

  return dollars.format(value)
}

export function getAvailableProviders(bookableSlots, currentTask) {
  const currentBooking = currentTask?.requisites?.find((x) => x.name === 'Booking')

  return bookableSlots.filter((x) => {
    const start = DateTime.fromISO(x.start, { zone: 'utc' }).toISO({ suppressMilliseconds: true })
    const end = DateTime.fromISO(x.end, { zone: 'utc' }).toISO({ suppressMilliseconds: true })

    const taskStart = DateTime.fromISO(currentTask?.value?.start, { zone: 'utc' }).toISO({ suppressMilliseconds: true })
    const taskEnd = DateTime.fromISO(currentTask?.value?.end, { zone: 'utc' }).toISO({ suppressMilliseconds: true })

    return start <= taskStart && end >= taskEnd && currentBooking?.value?.service_types?.every((service) => x.services?.includes(service))
  })
}

export function getExcludedProviders(zoneProviderEstimates, currentTask) {
  const excludeProviders = zoneProviderEstimates?.filter((x) => {
    if (x?.overlapping_events?.length) return true
    if (x?.previous_event && x?.travel_from?.duration >= 0) {
      const prevEventEnd = DateTime.fromISO(x.previous_event.end, { zone: 'utc' })
      const taskStart = DateTime.fromISO(currentTask.start, { zone: 'utc' })
      const difference = taskStart.diff(prevEventEnd, 'minutes')

      return difference.minutes < x.travel_from.duration
    }
    if (x?.next_event && x?.travel_to?.duration >= 0) {
      const nextEventStart = DateTime.fromISO(x.next_event.start, { zone: 'utc' })
      const taskEnd = DateTime.fromISO(currentTask.end, { zone: 'utc' })
      const difference = nextEventStart.diff(taskEnd, 'minutes')

      return difference.minutes < x.travel_to.duration
    }
  })
  return excludeProviders
}

export function parseValueByType(value, type) {
  switch (type) {
    case 'Boolean':
      return Boolean(value)
    case 'DateTime':
      return value ? DateTime.fromISO(value).toUTC().toString() : value
    case 'TimeRange':
      return value ? value + ':00' : value
    case 'PositiveInteger':
      return parseInt(value, 10)
    case 'Currency':
    case 'Number':
      return parseFloat(value)
    default:
      return value
  }
}

export function getOptionPriceByPartValue(part, partValue) {
  const partOption = part?.detail?.options?.find((option) => option.value === partValue)
  const partIndex = part?.detail?.options?.indexOf(partOption)
  const partPrice = part?.price_tags?.find((price) => price?.option_index === partIndex)

  return partPrice?.unit_price || 0
}

export function getPartValueByType(part) {
  switch (part?.detail?.type) {
    case 'MultiSelect':
      return part?.detail?.options
        ?.filter((x) => part?.detail?.value?.includes(x.value))
        ?.map((x) => x.name)
        ?.join(', ')
    case 'SingleSelect':
      return part?.detail?.options?.find((x) => x.value === part?.detail?.value)?.name
    default:
      return part?.detail?.value || ''
  }
}

export function getUnitPriceByType(part) {
  const flags = part?.detail?.flags || []

  let unitPrice = 0
  switch (part?.detail?.type) {
    case 'MultiSelect':
      let partTotal = 0
      part?.detail?.value?.map((value) => {
        partTotal = partTotal + getOptionPriceByPartValue(part, value) || 0
      })
      unitPrice = partTotal
      break
    case 'SingleSelect':
      unitPrice = getOptionPriceByPartValue(part, part?.detail?.value) || 0
      break
    default:
      unitPrice = part?.detail?.value && part?.detail?.value !== null ? part?.price_tags?.unit_price || 0 : 0
  }

  return unitPrice * (flags.includes('ValueAsQty') && !isNaN(part?.detail?.value) ? part?.detail?.value || 1 : 1)
}

export function getTimeIntervals(interval, startTime, endTime, timeZone) {
  const currentDate = DateTime.now().setZone(timeZone)
  const start = currentDate.set({
    hour: startTime,
    minute: 0,
    second: 0,
    millisecond: 0,
  })
  const end = currentDate.set({
    hour: endTime,
    minute: 30,
    second: 0,
    millisecond: 0,
  })

  const result = []
  let current = DateTime.fromISO(start, { zone: timeZone })

  while (current < end) {
    result.push(current)
    current = current.plus({ minute: interval })
  }

  return result
}

export function getPlatform(flags) {
  if (flags?.realhub_reference && flags?.campaigntrack_reference) return { logo: realhub }
  if (flags?.realhub_reference) {
    return { name: 'Realhub', logo: realhub, href: `${config.rhProjectReferenceUrl}/${flags?.realhub_reference}/edit` }
  }
  if (flags?.campaigntrack_reference) {
    return { name: 'Campaigntrack', logo: campaigntrack, href: `${config.ctProjectReferenceUrl}=${flags?.campaigntrack_reference}` }
  }
  return null
}
